<template>
	<main class="main">
		<template v-if="blocks && blocks.template != 'clear'">
			<section class="container">
				<Crumbs :data="crumbs"></Crumbs>

				<Wrapper
					v-if="blocks && blocks.block1"
					:data="{
						...blocks.block1,
						list: blocks.block1.content2,
						...imageBox,
					}"
					name="TopBlock"
				></Wrapper>
			</section>
			<section
				class="main__full-box"
				:class="{
					'main__gradient flex flex--middle': blocks.block2.background,
				}"
				v-if="blocks && blocks.block2"
			>
				<img
					v-if="blocks.block2.background"
					:src="blocks.block2.image"
					:alt="blocks.block2.title"
					class="main__full-box-image"
				/>
				<picture v-else>
					<img
						class="main__full-box-bg main__full-box-bg--big"
						:src="blocks.block2.image"
						:alt="blocks.block2.title"
					/>
				</picture>
				<div class="main__full-box-content">
					<h2 class="main__title">{{ blocks.block2.title }}</h2>
					<div class="editor" v-html="blocks.block2.content"></div>
				</div>
			</section>

			<section class="container" v-if="blocks && blocks.block4">
				<div class="main__box">
					<h2 class="main__title main__title--center">
						{{ blocks.block4.title }}
					</h2>
					<div
						class="main__text main__text--center main__text--max-width editor"
						v-html="blocks.block4.content"
					></div>
					<Wrapper
						v-if="blocks.block5"
						:data="[
							{
								...blocks.block5[0],
								icon: require('@/assets/images/icons/icon_lock-blue.svg'),
							},
							{
								...blocks.block5[1],
								icon: require('@/assets/images/icons/icon_credit-card-blue.svg'),
							},
							{
								...blocks.block5[2],
								icon: require('@/assets/images/icons/icon_check-blue.svg'),
							},
							{
								...blocks.block5[3],
								icon: require('@/assets/images/icons/icon_award-blue.svg'),
							},
						]"
						name="Advantages"
					></Wrapper>
				</div>
			</section>

			<section class="container" v-if="blocks && blocks.block3">
				<div class="main__box">
					<h2 class="main__title">Банк-участник</h2>
					<ul class="bank__list">
						<li class="bank__item">
							<div class="bank__logo-box">
								<img
									:src="require('@/assets/images/logos/logo_sinara-bank.svg')"
									:alt="blocks.block3.title"
									class="bank__logo"
								/>
							</div>
							<div class="bank__content">
								<h3 class="bank__title">{{ blocks.block3.title }}</h3>
								<div
									class="bank__text editor"
									v-html="blocks.block3.content"
								></div>
								<div class="flex">
									<a href="tel:8 800 1000-600" class="bank__link">
										<span class="icon icon--phone"></span>
										8 800 1000-600
									</a>
									<a
										href="https://sinara.ru/"
										target="_blank"
										class="bank__link"
									>
										<span class="icon icon--globe"></span>
										sinara.ru
									</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<template v-if="blocks && blocks.extra && blocks.extra.length > 0">
				<section
					class="container"
					v-for="(block, index) in blocks.extra"
					:key="index"
				>
					<Wrapper
						v-if="extraName(block.name)"
						:data="extraName(block.name).data"
						:name="extraName(block.name).name"
						:filters="extraName(block.name).filters"
					></Wrapper>
				</section>
			</template>
		</template>
		<template v-else-if="blocks && blocks.template == 'clear'">
			<section class="container">
				<Crumbs :data="crumbs"></Crumbs>

				<h1 class="main__title">{{ blocks.title }}</h1>

				<div class="editor" v-html="blocks.block1.content"></div>
			</section>
		</template>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import Wrapper from '@/components/DynamicWrapper.vue'

export default {
	name: 'Capabilities',

	components: {
		Crumbs,
		Wrapper,
	},

	props: {
		alias: {
			type: String,
			require: true,
		},
	},

	data() {
		return {
			imageBox: {
				animationBg: require('@/assets/images/animation/animation_circle-pattern.svg'),
				animationClassBg: 'animation-pattern',
				animationClassFront: 'animation-icon',
			},
		}
	},

	methods: {
		extraName(name) {
			let data = {
				name: null,
				data: null,
				filters: null,
			}

			if (name == 'StocksList') {
				const stocksList = this.$store.getters.LIST_LOYALTY_PROGRAMS
				const acceptors = this.$store.getters.LIST_ACCEPTORS

				let data = stocksList

				if (stocksList && acceptors) {
					data = []

					stocksList.forEach(stock => {
						acceptors.forEach(acceptor => {
							if (stock.acceptorId == acceptor.id) {
								stock.acceptorInfo = acceptor

								data.push(stock)
							}
						})
					})
				}

				data.name = 'Partners'

				if (stocksList) {
					data.data = {
						title: 'Акции',
						link: 'StocksItem',
						filterDispatch: 'SET_LIST_APPLIED_STOCKS_FILTERS',
						filterGetters: 'LIST_APPLIED_STOCKS_FILTERS',
						filterAll: true,
						list: data,
					}
					return data
				} else {
					return data
				}
			} else if (name == 'PartnersList') {
				data.name = 'PartnersBonus'
				data.data = {
					title: 'Партнеры программы лояльности',
					link: 'CapabilitiesProgram',
					filterDispatch: 'SET_LIST_APPLIED_ACCEPTOR_FILTERS',
					filterGetters: 'LIST_APPLIED_ACCEPTOR_FILTERS',
					filterAll: true,
					list: this.listAcceptors,
				}
				return data
			} else if (name == 'Map') {
				data.name = 'Map'
				data.data = {
					icon: require('@/assets/images/icons/map_type-3.svg'),
					markers: this.getPoints,
					title: 'Пункты приема заявлений и выдачи ЕСК',
				}
				data.filters = this.filters
				return data
			} else if (name == 'SliderNews') {
				data.name = 'SliderNews'
				return data
			} else if (name == 'News') {
				data.name = 'NewsAll'
				return data
			} else if (name == 'Contacts') {
				data.name = 'ContactsBottomBlock'
				data.data = 'Contacts'
				return data
			} else {
				return data
			}
		},
	},
	computed: {
		blocks() {
			const blocks = this.$store.getters.PAGE

			if (blocks && blocks[this.alias]) {
				if (
					blocks[this.alias] &&
					blocks[this.alias].extra &&
					blocks[this.alias].extra.length > 0
				) {
					blocks[this.alias].extra.forEach(item => {
						if (item.name == 'PartnersList') {
							this.$store.dispatch('GET_LIST_ACCEPTORS')
						} else if (item.name == 'StocksList') {
							this.$store.dispatch('GET_LIST_LOYALTY_PROGRAMS')
							this.$store.dispatch('GET_LIST_ACCEPTORS')
						} else if (item.name == 'Map') {
							this.$store.dispatch('GET_POINTS_ORGANIZATION')
						}
					})
				}

				document.title = blocks[this.alias].title

				return blocks[this.alias]
			} else {
				return null
			}
		},
		crumbs() {
			let crumbs = null
			let text = this.blocks ? this.blocks.title : ''

			crumbs = [
				{
					link: 'Capabilities',
					text: 'Возможности',
				},
				{
					link: null,
					text,
				},
			]

			return crumbs
		},

		listAcceptors() {
			return this.$store.getters.LIST_ACCEPTORS
		},

		getPoints() {
			return this.$store.getters.POINTS_ORGANIZATION
		},

		filters() {
			return {
				cardType: false,
				list: false,
				type: 'points',
				store: {
					dispatch: 'SET_POINT_LIST_APPLIED_FILTERS',
					getters: 'POINT_LIST_APPLIED_FILTERS',
				},
				filters: [
					{
						name: 'Фильтр',
						data: [
							{
								type: 'checkbox',
								cardType: true,
								title: 'Тип карты',
								list: [
									{
										id: 'isBankCardIssuer',
										name: 'Банковская',
									},
									{
										id: 'isNonFinancialCardIssuer',
										name: 'Нефинансовая',
									},
								],
							},
							{
								type: 'checkbox',
								title: 'Пункт выдачи ЕСК',
								list: this.$store.getters.POINT_LIST_FILTERS,
							},
						],
					},
				],
			}
		},
	},

	mounted() {
		this.$store.dispatch('GET_PAGE', this.alias)
	},
}
</script>
