<template>
	<component :is="component" :data="data" :filters="filters" v-if="component" />
</template>
<script>
export default {
	name: 'DynamicWrapper',

	props: {
		data: {
			type: [String, Number, Boolean, Array, Object],
		},
		name: {
			type: String,
			required: true,
		},
		filters: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			component: null,
		}
	},

	computed: {
		loader() {
			if (!this.name) {
				return null
			}
			return () => import(`@/components/${this.name}`)
		},
	},

	mounted() {
		this.loader()
			.then(() => {
				this.component = () => this.loader()
			})
			.catch(err => {
				console.log(err)
			})
	},
}
</script>
