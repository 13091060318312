var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[(_vm.blocks && _vm.blocks.template != 'clear')?[_c('section',{staticClass:"container"},[_c('Crumbs',{attrs:{"data":_vm.crumbs}}),(_vm.blocks && _vm.blocks.block1)?_c('Wrapper',{attrs:{"data":Object.assign({}, _vm.blocks.block1,
					{list: _vm.blocks.block1.content2},
					_vm.imageBox),"name":"TopBlock"}}):_vm._e()],1),(_vm.blocks && _vm.blocks.block2)?_c('section',{staticClass:"main__full-box",class:{
				'main__gradient flex flex--middle': _vm.blocks.block2.background,
			}},[(_vm.blocks.block2.background)?_c('img',{staticClass:"main__full-box-image",attrs:{"src":_vm.blocks.block2.image,"alt":_vm.blocks.block2.title}}):_c('picture',[_c('img',{staticClass:"main__full-box-bg main__full-box-bg--big",attrs:{"src":_vm.blocks.block2.image,"alt":_vm.blocks.block2.title}})]),_c('div',{staticClass:"main__full-box-content"},[_c('h2',{staticClass:"main__title"},[_vm._v(_vm._s(_vm.blocks.block2.title))]),_c('div',{staticClass:"editor",domProps:{"innerHTML":_vm._s(_vm.blocks.block2.content)}})])]):_vm._e(),(_vm.blocks && _vm.blocks.block4)?_c('section',{staticClass:"container"},[_c('div',{staticClass:"main__box"},[_c('h2',{staticClass:"main__title main__title--center"},[_vm._v(" "+_vm._s(_vm.blocks.block4.title)+" ")]),_c('div',{staticClass:"main__text main__text--center main__text--max-width editor",domProps:{"innerHTML":_vm._s(_vm.blocks.block4.content)}}),(_vm.blocks.block5)?_c('Wrapper',{attrs:{"data":[
						Object.assign({}, _vm.blocks.block5[0],
							{icon: require('@/assets/images/icons/icon_lock-blue.svg')}),
						Object.assign({}, _vm.blocks.block5[1],
							{icon: require('@/assets/images/icons/icon_credit-card-blue.svg')}),
						Object.assign({}, _vm.blocks.block5[2],
							{icon: require('@/assets/images/icons/icon_check-blue.svg')}),
						Object.assign({}, _vm.blocks.block5[3],
							{icon: require('@/assets/images/icons/icon_award-blue.svg')}) ],"name":"Advantages"}}):_vm._e()],1)]):_vm._e(),(_vm.blocks && _vm.blocks.block3)?_c('section',{staticClass:"container"},[_c('div',{staticClass:"main__box"},[_c('h2',{staticClass:"main__title"},[_vm._v("Банк-участник")]),_c('ul',{staticClass:"bank__list"},[_c('li',{staticClass:"bank__item"},[_c('div',{staticClass:"bank__logo-box"},[_c('img',{staticClass:"bank__logo",attrs:{"src":require('@/assets/images/logos/logo_sinara-bank.svg'),"alt":_vm.blocks.block3.title}})]),_c('div',{staticClass:"bank__content"},[_c('h3',{staticClass:"bank__title"},[_vm._v(_vm._s(_vm.blocks.block3.title))]),_c('div',{staticClass:"bank__text editor",domProps:{"innerHTML":_vm._s(_vm.blocks.block3.content)}}),_vm._m(0)])])])])]):_vm._e(),(_vm.blocks && _vm.blocks.extra && _vm.blocks.extra.length > 0)?_vm._l((_vm.blocks.extra),function(block,index){return _c('section',{key:index,staticClass:"container"},[(_vm.extraName(block.name))?_c('Wrapper',{attrs:{"data":_vm.extraName(block.name).data,"name":_vm.extraName(block.name).name,"filters":_vm.extraName(block.name).filters}}):_vm._e()],1)}):_vm._e()]:(_vm.blocks && _vm.blocks.template == 'clear')?[_c('section',{staticClass:"container"},[_c('Crumbs',{attrs:{"data":_vm.crumbs}}),_c('h1',{staticClass:"main__title"},[_vm._v(_vm._s(_vm.blocks.title))]),_c('div',{staticClass:"editor",domProps:{"innerHTML":_vm._s(_vm.blocks.block1.content)}})],1)]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('a',{staticClass:"bank__link",attrs:{"href":"tel:8 800 1000-600"}},[_c('span',{staticClass:"icon icon--phone"}),_vm._v(" 8 800 1000-600 ")]),_c('a',{staticClass:"bank__link",attrs:{"href":"https://sinara.ru/","target":"_blank"}},[_c('span',{staticClass:"icon icon--globe"}),_vm._v(" sinara.ru ")])])}]

export { render, staticRenderFns }